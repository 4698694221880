import axios, { AxiosResponse } from 'axios'
import { apiClient } from '../utils/request'

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  async error => {
    if (error.response?.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken')
      if (!refreshToken) return Promise.reject(error)
      await authenticationAPI.refreshAccessToken({
        refresh_token: refreshToken,
        grant_type: 'refresh_token'
      })
      .then((res: IRefreshTokenResponse) => {
        error.config.headers.Authorization = `Bearer ${res.id_token}`
        // retry request with a new token
        return axios(error.config)
      })
      .catch(() => {
        return Promise.reject(error)
      });
    }
    return Promise.reject(error)
  }
)

export interface IAccessTokenRequest {
  code: string
  state: string
  redirect_uri: string
  grant_type: string
}

export interface IAccessTokenResponse {
  access_token: string
  id_token: string
  refresh_token: string
}

export interface IRefreshTokenResponse {
  access_token: string
  id_token: string
}

export interface IRefreshAccessTokenRequest {
  refresh_token: string
  grant_type: string
}

class AuthenticationAPI {
  async getAccessToken(data: IAccessTokenRequest): Promise<IAccessTokenResponse> {
    const res: AxiosResponse = await apiClient({
      method: 'POST',
      url: '/getToken',
      data,
    })
    const { id_token, refresh_token } = res.data.result
    localStorage.setItem('idToken', id_token)
    localStorage.setItem('refreshToken', refresh_token)
    return res.data.result
  }

  async getUserInfo(): Promise<any> {
    const res: AxiosResponse = await apiClient({
      method: 'GET',
      url: '/getUserInfo',
    })
    return res.data.result
  }

  async refreshAccessToken(data: IRefreshAccessTokenRequest): Promise<IRefreshTokenResponse> {
    const res: AxiosResponse = await apiClient({
      method: 'POST',
      url: '/refreshToken',
      data,
    })
    const { id_token } = res.data.result
    localStorage.setItem('idToken', id_token)
    return res.data.result
  }

  logout() {
    localStorage.removeItem('idToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('openai-api-key')
    localStorage.removeItem('openai-api-key-popup')
  }
}

const authenticationAPI = new AuthenticationAPI()

export default authenticationAPI
