import { forwardRef, MutableRefObject, useState } from "react"
import { CircularProgress } from "@mui/material"
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  GridValidRowModel,
  GridPaginationModel,
  GridSortModel,
  GridCallbackDetails,
  GridRowIdGetter,
} from "@mui/x-data-grid"
interface DataGridComponentProps {
  apiRef?: MutableRefObject<any>
  data: GridValidRowModel[]
  columns: GridColDef<GridValidRowModel, any, any>[]
  sortModel?: GridSortModel
  onSortModelChange?: (model: GridSortModel, details: GridCallbackDetails<any>) => void
  getRowId?: GridRowIdGetter<GridValidRowModel> | undefined
  isLoading?: boolean
}

const DataGrid = forwardRef((props: DataGridComponentProps, ref: any) => {
  const { data, columns, isLoading, apiRef } = props
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 10 })

  const onPaginationModelChange = (model: GridPaginationModel) => {
    setPaginationModel(model)
  }

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div style={{ height: "100%", width: "100%" }}>
          <MuiDataGrid
            sx={{
              borderRadius: '4px',
              '.MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '.MuiDataGrid-columnHeader': {
                color: '#262626',
                backgroundColor: '#fafafa',
              },
              '.MuiDataGrid-footerContainer': {
                color: '#262626',
                backgroundColor: '#fafafa',
              },
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 500,
              },
              '&.MuiDataGrid-root': {
                color: '#262626',
                backgroundColor: '#ffffff',
              },
            }}
            apiRef={apiRef}
            rows={data}
            getRowId={props.getRowId}
            columns={columns}
            sortModel={props.sortModel}
            onSortModelChange={props.onSortModelChange}
            disableRowSelectionOnClick
            autoHeight
            disableColumnMenu
            disableColumnSelector
            pagination
            paginationModel={paginationModel}
            pageSizeOptions={[10, 25, 50, 100]}
            onPaginationModelChange={onPaginationModelChange}
          />
        </div>
      )}
    </>
  )
})

export default DataGrid

