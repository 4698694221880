import { useMemo } from "react"
import { Alert } from '@mui/material'
import DataGrid from './DataGrid'

import { IResultResponse } from "../apis/query.api"

type ComponentProps = {
    data: IResultResponse
}

const SQLQueryResult = ({ data }: ComponentProps) => {
    const error = useMemo(() => {
        return typeof data.result === 'string' && data.result !== '' ? data.result : null
    }, [data])

    const rows = useMemo(() => {
        if (typeof data.result === 'object') {
            return data.result.map((obj: any, idx: number) => {
                obj.custom_id = `${idx}`
                return obj
            })
        }
        return []
    }, [data])

    const columns = useMemo(() => {
        if (typeof data.result === 'object' && data.result.length) {
            const obj = data.result[0]
            const cols = Object.entries(obj).map(([key, value]) => {
                return { field: key, headerName: key, width: 200, filterable: false, flex: 1 }
            }).filter(obj => obj.field !== 'custom_id')
            return cols
        }
        return []
    }, [data])

    if (error !== null) {
        return <Alert severity="error">{error}</Alert>
    }

    return (
        <div>
            <DataGrid getRowId={(row) => row.custom_id} data={rows} columns={columns} />
        </div>
    )
}

export default SQLQueryResult
