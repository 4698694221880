import { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Grid,
  Tabs,
  Tab,
  Stack,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material'
import { useAuth } from '../hooks'
import QueryHistoryTable from './query-history/QueryHistoryTable'
import { format } from 'sql-formatter'
import SQLQueryEditor from "./SQLQueryEditor"
import SchemaView from './SchemaView'
import SearchBar from './SearchBar'
import { queryAPI } from '../apis'
import queryHistoryManager, { QueryExecutionStatusEnum } from '../utils/query-history-manager'
import { IGenerateQueryResponse, IGetResultApiResponse, IResultResponse, IRunQueryResponse } from '../apis/query.api'
import SQLQueryResults from './SQLQueryResults'
import LoadingOverlay from './common/LoadingOverlay'
import ApiKeyModal from './ApiKeyModal'
import osqueryLogo from '../assets/img/osquery-logo.svg'
import chatgptLogo from '../assets/img/chatgpt-logo.svg'
import BounceLoaderEffect from './common/BounceLoaderEffect'
import { useLocation } from 'react-router-dom'
import { useAppContext } from '../hooks/app-context'

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           {children}
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

interface CustomLoadingOverlayProps {
  open: boolean
  type?: 'chatGPT' | 'OSquery'
}

const CustomLoadingOverlay = ({ open, type }: CustomLoadingOverlayProps) => {
  if (type === 'chatGPT') {
    return (
      <LoadingOverlay open={open}>
        <Stack direction="row" spacing={2} alignItems="center">
          <img src={chatgptLogo} alt="logo" style={{ height: '40px' }} />
          <Typography variant="h6">Generating query</Typography>
          <BounceLoaderEffect />
        </Stack>
      </LoadingOverlay>
    )
  }
  if (type === 'OSquery') {
    return (
      <LoadingOverlay open={open}>
        <Stack direction="row" spacing={2} alignItems="center">
          <img src={osqueryLogo} alt="logo" style={{ height: '40px' }} />
          <Typography variant="h6">Running query</Typography>
          <BounceLoaderEffect />
        </Stack>
      </LoadingOverlay>
    )
  }
  return (
    <LoadingOverlay open={open} />
  )
}

// const UserSettings: React.FC<any> = () => {
//   const { logout, user } = useAuth()
//   const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

//   const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
//     setAnchorElUser(event.currentTarget);
//   };

//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };

//   return (
//     <Box sx={{ px: '24px', marginLeft: 'auto' }}>
//       <IconButton size="small" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
//         <Avatar sx={{ width: 28, height: 28, color: 'primary.main', backgroundColor: '#f0e8fc' }} alt={user?.name} src={user?.picture || ''} />
//       </IconButton>
//       <Menu
//         id="menu-appbar"
//         anchorEl={anchorElUser}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'right',
//         }}
//         keepMounted
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'right',
//         }}
//         open={Boolean(anchorElUser)}
//         onClose={handleCloseUserMenu}
//       >
//         <MenuItem key="logout-menu" onClick={() => logout()}>
//           <Typography textAlign="center">Logout</Typography>
//         </MenuItem>
//       </Menu>
//     </Box>
//   )
// }

const Home = () => {
  const [question, setQuestion] = useState<string>('')
  const [sqlQuery, setSqlQuery] = useState<string>('')
  const [sqlQueryResult, setSqlQueryResult] = useState<IGetResultApiResponse>()
  const [loading, setLoading] = useState(false)
  const [loadingOverlay, setLoadingOverlay] = useState<'chatGPT' | 'OSquery'>()
  const [error, setError] = useState<string>('')
  // const [openApiKeyModal, setOpenApiKeyModel] = useState<boolean>(false)
  const { addQuestion, setQuestionBeingAsked } = useAppContext()

  const location = useLocation()

  useEffect(() => {
    if (location.state?.question) {
      setQuestionBeingAsked(location.state?.question)
      setQuestion(location.state?.question)
      generateQuery(location.state?.question)
    }
  }, [location])

  const onQuestionChange = (event: any) => {
    setQuestion(event.target.value)
  }

  const onSqlQueryChange = (value: string, event: any) => {
    setSqlQuery(value)
  }

  // useEffect(()=>{
  //   if (localStorage.getItem('openai-api-key') !== null) {
  //     setOpenApiKeyModel(false);
  //   } else {
  //     setOpenApiKeyModel(true);
  //   }
  // }, [])

  const delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  const getResult = (id: string, query: string, ques?: string) => {
    queryAPI.getResult({ id }).then((res: IGetResultApiResponse) => {
        // handle open ai error msg
        if (res.msg) {
          setLoading(false)
          setError(res.msg)
          return
        }
        if (typeof res.results === 'string') {
          // delay 3 sec 
          delay(3000).then(() => getResult(id, query, ques))
          return
        }
        setLoading(false)
        setSqlQueryResult(res)
        let queryStatus: QueryExecutionStatusEnum  = QueryExecutionStatusEnum.Failed

        res.results.forEach((obj: IResultResponse) => {
          if (typeof obj.result === 'object') {
            queryStatus = QueryExecutionStatusEnum.Completed
          }
        })

        queryHistoryManager.addItem({
          id,
          query,
          question: ques,
          executedOn: Date.now(),
          status: queryStatus,
        })
    }).catch((error: any) => {
      setLoading(false)
      setError(error.message)
    })
  }

  const runQuery = (query: string) => {
    setLoading(true)
    setLoadingOverlay('OSquery')
    setError('')
    queryAPI.runQuery({ query }).then((res: IRunQueryResponse) => {
      setSqlQuery(format(query, { language: 'mysql' }))
      // handle open ai error msg
      if (res.msg) {
        setLoading(false)
        setError(res.msg)
      } else {
        getResult(res.id, res.query)
      }
    }).catch((error: any) => {
      setLoading(false)
      setError(error.message)
    })
  }

  const generateQuery = (ques: string) => {
    setLoading(true)
    setLoadingOverlay('chatGPT')
    setError('')
    addQuestion(ques)
    const chatGPTApiKey = localStorage.getItem('openai-api-key') || undefined
    queryAPI.generateQuery({ question: ques, api_key: chatGPTApiKey }).then((res: IGenerateQueryResponse) => {
      // handle open ai error msg
      if (res.msg) {
        // if provided API key is invalid
        // if (res.msg.includes('Incorrect API key provided')) {
        // }
        // if (res.msg.includes('Open ai error Rate limit reached')) {
        // }
        setLoading(false)
        setError(res.msg)
      } else {
        setSqlQuery(format(res.query, { language: 'mysql' }))
        setLoadingOverlay('OSquery')
        getResult(res.id, res.query, res.question)
      }
    }).catch((error: any) => {
      setLoading(false)
      setError(error.message)
    })
  }

  const SqlQueryResultComponent = useMemo(() => {
    if (typeof sqlQueryResult?.results === 'string') return null
      return <SQLQueryResults loading={loading} error={error} data={sqlQueryResult?.results || []} />
  }, [sqlQueryResult, loading, error])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack direction="column" spacing={2}>
        <SQLQueryEditor value={sqlQuery} onChange={onSqlQueryChange} onExecute={runQuery} />
        {SqlQueryResultComponent}
      </Stack>
      <CustomLoadingOverlay open={loading} type={loadingOverlay} />
    </Box>
  )
}

export default Home
