export enum QueryExecutionStatusEnum {
  Failed = 'Failed',
  Completed = 'Completed',
}

type QueryItem = {
  id: string
  question?: string
  query: string
  executedOn: number
  status: QueryExecutionStatusEnum
}

// maximum 20 queries are stored
const MAX_SIZE = 20
class QueryHistoryManager {
  private storageKey: string = 'query-history';

  public addItem(item: QueryItem): void {
    let items = this.getItems();
    if (items.length === MAX_SIZE) {
      [, ...items] = items // remove first item
    }
    items = [...items, item] // append new item
    localStorage.setItem(this.storageKey, JSON.stringify(items));
  }

  public getItems(): QueryItem[] {
    const storedItems = localStorage.getItem(this.storageKey);
    return storedItems ? JSON.parse(storedItems) : [];
  }

  public removeItems() {
    localStorage.setItem(this.storageKey, JSON.stringify([]));
  }
}

const queryHistoryManager = new QueryHistoryManager()

export default queryHistoryManager
