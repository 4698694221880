import { useEffect, useState } from "react"
import { Menu, MenuItem, Button, Tooltip, IconButton, Typography, Stack, Card, CardHeader, CardContent, styled } from '@mui/material'
import { MoreVertOutlined, ContentCopy as ContentCopyIcon, Done as DoneIcon } from '@mui/icons-material'
import AceEditor from "react-ace"
import { format } from 'sql-formatter';
import "ace-builds/src-noconflict/mode-mysql"
import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/theme-tomorrow"
import "ace-builds/src-noconflict/ext-language_tools"

const CardContentNoPadding = styled(CardContent)(({ theme }) => ({
    minHeight: '320px',
    padding: '0',
    '&:last-child': {
        paddingBottom: '0',
    }
}));

type ComponentProps = {
    value: string
    onChange?: (value: string, event?: any) => void
    onExecute?: (query: string) => void
}

const SQLQueryEditor = ({ value, onChange, onExecute }: ComponentProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [contentCopied, setContentCopied] = useState(false)

    useEffect(() => {
        let timeoutFunc: any = undefined
        if (contentCopied) {
            timeoutFunc = setTimeout(() => {
                setContentCopied(false)
            }, 2000)
        }
        return () => clearTimeout(timeoutFunc)
    }, [contentCopied])

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const closeMenu = () => {
        setAnchorEl(null)
    }

    const onClear = (event: any) => {
        onChange?.('')
        closeMenu()
    }

    const onCopyAll = () => {
        setContentCopied(true)
        navigator.clipboard.writeText(value)
    }

    return (
        <Card variant="outlined">
            <CardHeader
            color="primary"
            title={<Typography variant="body1">SQL Editor</Typography>}
            action={
                <Stack direction="row" spacing={1} sx={{ mr: '8px' }}>
                    {contentCopied ? (
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <DoneIcon />
                            <p>Copied!</p>
                        </Stack>
                    ) : (
                        <Tooltip title="Copy content">
                            <IconButton
                                size="small"
                                onClick={onCopyAll}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <div>
                        <Tooltip title="Actions">
                            <IconButton
                                size="small"
                                sx={{
                                    border: '1px solid',
                                    borderRadius: '4px'
                                }}
                                aria-label="more"
                                id="long-button"
                                aria-controls={Boolean(anchorEl) ? 'long-menu' : undefined}
                                aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={openMenu}
                            >
                                <MoreVertOutlined />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="long-menu"
                            MenuListProps={{
                            'aria-labelledby': 'long-button',
                            }}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={closeMenu}
                        >
                            <MenuItem key="clear" onClick={onClear}>
                                Clear
                            </MenuItem>
                        </Menu>
                    </div>
                    <Button disabled={!value.trim()} size="small" variant="contained" onClick={() => onExecute?.(value)}>Run SQL</Button>
                </Stack>
            }
            />
            <CardContentNoPadding>
                <AceEditor
                    width="100%"
                    height="320px"
                    placeholder="Write SQL queries here"
                    mode="mysql"
                    theme="tomorrow"
                    name="sql-query-editor"
                    fontSize={16}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={value}
                    onChange={onChange}
                    setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2,
                    }}
                />
            </CardContentNoPadding>
        </Card>
    )
}

export default SQLQueryEditor
