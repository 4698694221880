import { Backdrop, CircularProgress } from '@mui/material'

interface Props {
  open: boolean
  children?: React.ReactNode
}

const LoadingOverlay = ({ open, children }: Props) => {
  return (
    <Backdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      {children || <CircularProgress />}
    </Backdrop>
  )
}

export default LoadingOverlay
