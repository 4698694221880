import React, { useState } from 'react'
import {
  Box,
  Stack,
  Typography,
  Tooltip,
  Grid,
  Container,
  Link,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import SearchBar from './SearchBar'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import exampleIcon from '../assets/img/examples-icon.svg'
import featuresIcon from '../assets/img/features-icon.svg'
import limitationsIcon from '../assets/img/limitations-icon.svg'
import AppLogo from './common/AppLogo';
import logo from '../assets/img/uptycs-logo.png'
import ApiKeyModal from './ApiKeyModal'
import windowsLogo from '../assets/img/windows-logo.svg'
import linuxLogo from '../assets/img/linux-logo.svg'
import rhelLogo from '../assets/img/rhel-logo.svg'
import macLogo from '../assets/img/mac-logo.svg'
import { PlatformEnum } from '../utils/constants';

const NewQuestion = () => {
  const [question, setQuestion] = useState<string>('')
  const navigate = useNavigate()
  const [openApiKeyModal, setOpenApiKeyModel] = useState<boolean>(false)

  const onQuestionChange = (event: any) => {
    setQuestion(event.target.value)
  }

  const Example = ({ question }: any) => {
    return (
      <Box onClick={() => handleSearch(question)} sx={{ lineHeight: '1.5rem', p: '1rem', borderRadius: '8px', backgroundColor: '#f7f7f8', cursor: 'pointer' }}>
        <Typography variant="body2">{question} →</Typography>
      </Box>
    )
  }

  const Feature = ({ feature }: any) => {
    return (
      <Box sx={{ lineHeight: '1.5rem', p: '1rem', borderRadius: '8px', backgroundColor: '#f7f7f8' }}>
        <Typography variant="body2">{feature}</Typography>
      </Box>
    )
  }

  const handleSearch = (ques: string) => {
    navigate('/home', { state: {
      question: ques
    }})
  }

  return (
    <Container maxWidth="lg" sx={{ marginTop: '-32px', height: '100%', display: 'flex', alignItems: 'center' }}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <Box sx={{ p: '24px', display: "flex", justifyContent: 'center' }}>
            <AppLogo height="40px" compact />
            {/* <Stack direction="row" spacing={0.5} alignItems="center" sx={{ alignSelf: 'flex-end' }}>
              <Typography variant="body1">by</Typography>
              <img src={logo} alt="logo" style={{ height: '1.2rem' }} />
            </Stack> */}
          </Box>
          {/* <Typography variant='h5' textAlign="center" sx={{ fontWeight: '600' }}>
            Making OSquery simpler using ChatGPT
          </Typography> */}
        </Grid>
        <Grid item sm={6} md={4}>
          <Stack spacing={2} direction="column">
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
              <img src={exampleIcon} height="24px" alt="logo" />
              <Typography sx={{
                fontWeight: '400',
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
              }}
              >
                Examples
              </Typography>
            </Stack>
            <Example question={'List all python packages with MIT lisence'} />
            <Example question={'List all windows programs not installed in program files directory'} />
            <Example question={'List unique publishers for installed windows softwares'} />
            <Example question={'List all rpm packages installed in /usr/lib'} />
            <Example question={'List all rules in /etc/hosts'} />
            <Example question={'Total memory used by all processes in mb'} />
            {/* <Example question={'Get windows update history'} /> */}
          </Stack>
        </Grid>
        <Grid item sm={6} md={4}>
          <Stack spacing={2} direction="column">
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
              <img src={featuresIcon} height="24px" alt="logo" />
              <Typography sx={{
                fontWeight: '400',
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
              }}
              >
                Features
              </Typography>
            </Stack>
            <Feature feature={'Osquery version: 5.8.2 (open source)'} />
            <Feature feature={<p>Schema: <Link sx={{ color: 'inherit' }} href="https://osquery.io/schema" target='_blank' rel="noreferrer">https://osquery.io/schema</Link> (open source)</p>} />
            <Feature feature={'Model: ChatGpt 3.5 Turbo'} />
            <Feature feature={'OS supported: Ubuntu, RedHat, Windows'} />
            <Feature feature={<Link sx={{ color: 'inherit' }} href="#" onClick={() => setOpenApiKeyModel(true)}>Use your own OpenAI API key</Link>} />
          </Stack>
        </Grid>
        <Grid item sm={6} md={4}>
          <Stack spacing={2} direction="column">
            <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
              <img src={limitationsIcon} height="24px" alt="logo" />
              <Typography sx={{
                fontWeight: '400',
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
              }}
              >
                Limitations
              </Typography>
            </Stack>
            <Feature feature={'May occasionally generate incorrect sql (retry, rephrase)'} />
            <Feature feature={'Not all sql will produce results since the corresponding data may not be available in the deployed osquery instances'} />
            <Feature feature={'Some Sqls may result in error on osquery'} />
            <Feature feature={'Limited knowledge of schema without description (because of token limit)'} />
            <Feature feature={'Free accounts used so rate limits may get hit time to time (Try using your own API Key)'} />
          </Stack>
        </Grid>
        <ApiKeyModal open={openApiKeyModal} setOpen={setOpenApiKeyModel} />
      </Grid>
    </Container>
  )
}

export default NewQuestion