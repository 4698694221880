import { useEffect, useState } from "react"
import { Box, TextField as MuiTextField, styled, Stack, InputAdornment, Button, Card, CardHeader, CardContent, Typography } from "@mui/material"
import { ClearAllOutlined, DownloadOutlined, SearchOutlined } from "@mui/icons-material"
import { GridCsvExportOptions, GridSortModel, useGridApiRef } from "@mui/x-data-grid"
import queryHistoryManager from "../../utils/query-history-manager"
import DataGrid from "../DataGrid"
import columns from './tableColumnDef'

const TextField = styled(MuiTextField)(() => ({
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      minWidth: '320px',
    },
}))

const QueryHistoryTable = () => {
    const [items, setItems] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState<string>('')
    const gridRef = useGridApiRef()
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
          field: 'executedOn',
          sort: 'desc',
        },
    ])

    useEffect(() => {
        setItems(queryHistoryManager.getItems())
    }, [])

    useEffect(() => {
        gridRef?.current?.setQuickFilterValues([searchTerm]);
    }, [searchTerm, gridRef])

    const handleSearch = (e: any) => {
        setSearchTerm(e.target.value)
    };

    const handleClearAll = () => {
        queryHistoryManager.removeItems()
        setItems([])
    }

    const handleDownload = () => {
        const options: GridCsvExportOptions = {
            includeHeaders: true,
            fileName: 'query-history'
        }
        gridRef.current.exportDataAsCsv(options)
    }

    const Output = ({ children }: any) => {
        return (
          <Card variant="outlined">
            <CardHeader
              color="primary"
              title={<Typography variant="body1">History</Typography>}
              action={
                <Stack sx={{ mr: '8px' }} direction="row" spacing={1} justifyContent="flex-end">
                    <TextField
                        sx={{ marginRight: 'auto' }}
                        id="search-query-history"
                        size="small"
                        type="search"
                        label={undefined}
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <SearchOutlined />
                            </InputAdornment>
                        }}
                    />
                    <Button variant="outlined" size="small" startIcon={<ClearAllOutlined />} onClick={handleClearAll}>Clear all</Button>
                    <Button variant="outlined" size="small" startIcon={<DownloadOutlined />} onClick={handleDownload}>Download as CSV</Button>
                </Stack>
              }
            />
            <CardContent>
              {children}
            </CardContent>
          </Card>
        )
     }

    return (
        <Output>
            <Box sx={{ flexGrow: 1 }}>
                <DataGrid
                    apiRef={gridRef}
                    data={items}
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel: GridSortModel) => setSortModel(newSortModel)}
                />
            </Box>
        </Output>
    )
}

export default QueryHistoryTable