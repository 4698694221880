import './BounceLoaderEffect.css'

const BounceLoaderEffect = () => {
  return (
    <div className="spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  )
}

export default BounceLoaderEffect
