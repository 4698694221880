import { Suspense } from 'react'
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'
import { createTheme } from '@mui/material'
import { ThemeProvider } from '@emotion/react'
import { GoogleOAuthProvider } from '@react-oauth/google'
// import logo from './logo.svg';
import { ProvideAuth } from './hooks/check-auth'
import { ProvideAppContext } from './hooks/app-context'
import Home from './components/Home'
import Login from './components/Login'
import RequireAuth from './components/RequireAuth'
import { Navigate } from 'react-router-dom'
import './App.css'
import ErrorBoundary from './components/ErrorBoundary'
import AppLayout from './components/AppLayout'
import QueryHistoryTable from './components/query-history/QueryHistoryTable'
import SchemaView from './components/SchemaView'
import NewQuestion from './components/NewQuestion'

function App() {
  // custom theme
  const theme = createTheme({
    palette: {
      primary: {
        main: '#621BD0',
        light: '#8148D9',
        dark: '##441291',
      }
    }
  })

  const router = createBrowserRouter([
    { path: '/', element: <Login /> },
    { path: '/login', element: <Login /> },
    { path: '/login/callback', element: <Login /> },
    {
      element: <RequireAuth />,
      children: [
        {
          path: "/search",
          element: <AppLayout><NewQuestion /></AppLayout>,
        },
        {
          path: "/home",
          element: <AppLayout><Home /></AppLayout>,
        },
        {
          path: "/history",
          element: <AppLayout><QueryHistoryTable /></AppLayout>,
        },
        {
          path: "/schema",
          element: <AppLayout><SchemaView /></AppLayout>,
        },
      ]
    },
    {
      path: '/*',
      element: <Navigate to={{ pathname: '/home' }} />,
    },
  ])

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''}>
          <ProvideAuth>
            <ProvideAppContext>
              <Suspense>
                <RouterProvider router={router} />
              </Suspense>
            </ProvideAppContext>
          </ProvideAuth>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App
