import { useEffect, useState } from "react";
import { useNavigate } from "react-router"
import {
  InputBase,
  IconButton,
  styled,
  alpha,
  InputAdornment,
  TextField as MuiTextField,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useAppContext } from "../hooks/app-context";
import { SearchOutlined } from "@mui/icons-material";

const TextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 3),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '56ch',
      '&:focus': {
        width: '64ch',
      },
    }
  },
  "& .Mui-focused fieldset": {
    border: '1px solid #0000003b !important',
  },
  ":hover fieldset": {
    border: '1px solid #0000003b !important',
  },
  '& .MuiOutlinedInput-root': {
    minHeight: '48px',
    borderRadius: '24px',
  },
}))

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  // marginLeft: 0,
  width: 'auto',
  // [theme.breakpoints.up('sm')]: {
  //   marginLeft: theme.spacing(1),
  //   width: 'auto',
  // },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  display: 'inline-block',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 3),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '32ch',
      '&:focus': {
        width: '48ch',
      },
    },
  },
}));

type ComponentProps = {
  value?: string,
}

const MenuSearchBar = (props: ComponentProps) => {
  const { questionBeingAsked, setQuestionBeingAsked } = useAppContext()
  const navigate = useNavigate()

  const handleOnChange = (event: any) => {
    setQuestionBeingAsked(event.target.value)
  }

  const handleSearch = () => {
    navigate('/home', { state: {
      question: questionBeingAsked
    }})
  }

  const handleKeyPress = (e: any) => {
    // search when enter key is pressed
    if (e.keyCode === 13) {
      handleSearch()
    }
  }

  return (
    // <Search>
    //   <StyledInputBase
    //     value={questionBeingAsked}
    //     autoFocus
    //     onChange={handleOnChange}
    //     onKeyDown={handleKeyPress}
    //     placeholder="Type your question..."
    //     inputProps={{ 'aria-label': 'search' }}
    //   />
    //   <SearchIconWrapper onClick={() => {
    //     handleSearch()
    //   }}>
    //     <IconButton color="inherit" size="small">
    //       <SearchIcon />
    //     </IconButton>
    //   </SearchIconWrapper>
    // </Search>
    <TextField
        id="query-search-ip"
        inputRef={input => input && input.focus()}
        // autoFocus
        type="search"
        size="small"
        label={undefined}
        placeholder="Type your question..."
        value={questionBeingAsked}
        onChange={handleOnChange}
        onKeyDown={handleKeyPress}
        // focused
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={!questionBeingAsked?.trim()} size="small" onClick={() => handleSearch() }><SearchOutlined /></IconButton>
            </InputAdornment>
          ),
        }}
      />
  )
}

export default MenuSearchBar
