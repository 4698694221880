import { createContext, useContext, useEffect, useState } from 'react'

interface IAppContext {
  questionBeingAsked?: string
  setQuestionBeingAsked: React.Dispatch<React.SetStateAction<string | undefined>>
  questions: string[]
  addQuestion: (question: string) => void
}

export const appContext = createContext<IAppContext>({} as IAppContext)

const MAX_LIMIT = 15

const useAppContextHook = () => {
  const [questionBeingAsked, setQuestionBeingAsked] = useState<string | undefined>()
  const [questions, setQuestions] = useState<string[]>([])
  const storageKey: string = 'conversation'

  useEffect(() => {
    const storedItems = localStorage.getItem(storageKey);
    setQuestions(storedItems ? JSON.parse(storedItems) : [])
  }, [])

  const addQuestion = (item: string): void =>  {
    let items: string[] = Object.assign([], questions);
    if (items.length === MAX_LIMIT) {
      items.pop()
    }
    items = [item, ...items] // prepend new item
    const uniqueItems: any = new Set(items)
    setQuestions([...uniqueItems])
    localStorage.setItem(storageKey, JSON.stringify([...uniqueItems]));
  }

  return {
    questionBeingAsked,
    setQuestionBeingAsked,
    questions,
    addQuestion,
  }
}

interface Props {
  children: React.ReactNode
}

export const ProvideAppContext = ({ children }: Props) => {
  return <appContext.Provider value={useAppContextHook()}>{children}</appContext.Provider>
}

export const useAppContext = () => {
  return useContext(appContext)
}
