import { Chip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { convertDateInStandardFormat } from "../../utils/formatting/date";
import { QueryExecutionStatusEnum } from "../../utils/query-history-manager";
import CopyTextToClipboard from "../common/CopyTextToClipboard";

const columns: GridColDef[] = [
  { field: "id", headerName: "Excecution ID", width: 150, filterable: false },
  {
    field: "query",
    headerName: "Query",
    width: 300,
    filterable: false,
    flex: 1,
    renderCell: (params: any) => <CopyTextToClipboard value={params.value}/>,
  },
  {
    field: "question",
    headerName: "Question",
    maxWidth: 300,
    filterable: false,
    flex: 1,
    renderCell: (params: any) => <CopyTextToClipboard value={params.value}/>,
  },
  { field: "executedOn", headerName: "Executed On", width: 180, filterable: false, valueFormatter: (props: any) => convertDateInStandardFormat(props.value) },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    filterable: false,
    renderCell: (params: any) => {
      return (
        <>
          {
            params.value === QueryExecutionStatusEnum.Completed ? (
              <Chip size="small" label={params.value} variant="outlined" color="success" />
            ) : params.value === QueryExecutionStatusEnum.Failed ? (
              <Chip size="small" label={params.value} variant="outlined" color="error" />
            ) : <Chip size="small" label={params.value} variant="outlined" />
          }
        </>
      )
    }
  },
  // {
  //   field: "action",
  //   headerName: "Action",
  //   width: 150,
  //   filterable: false,
  //   sortable: false,
  //   renderCell: (params: any) => {
  //     return (
  //       <Tooltip title="Copy query">
  //         <IconButton size="small" onClick={() => navigator.clipboard.writeText(params.row.query)}>
  //           <ContentCopyOutlined fontSize="small" />
  //         </IconButton>
  //       </Tooltip>
  //     )
  //   },
  // },
];

export default columns;
