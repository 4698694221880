import {
  Alert,
  Box,
  Stack,
  Toolbar,
  styled
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import AppBar from './AppBar'
import AppSideBar from './AppSideBar'
import ApiKeyModal from './ApiKeyModal'
import './AppLayout.css'
import { useAppContext } from '../hooks/app-context'
import MenuSearchBar from './MenuSearchBar'
import SessionInactive from './SessionInactive'

interface ComponentProps {
  pageTitle?: string
  enableMenuSearchBar?: boolean
  children?: React.ReactNode
}

export default function AppLayout({ pageTitle, children, enableMenuSearchBar }: ComponentProps) {
  const [openApiKeyModal, setOpenApiKeyModel] = useState<boolean>(false)

  useEffect(() => {
    const showAPIKeyPopup = localStorage.getItem('openai-api-key-popup')
    if (!(showAPIKeyPopup && JSON.parse(showAPIKeyPopup))) {
      localStorage.setItem('openai-api-key-popup', 'true')
      setOpenApiKeyModel(true);
    } else {
      setOpenApiKeyModel(false);
    }
  }, [])

  return (
    <Box sx={{ height: '100%', display: 'flex' }}>
      <AppSideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Box sx={{ marginBottom: 2, textAlign: 'center' }}><MenuSearchBar /></Box>
          <Alert severity="info" sx={{ marginBottom: 2, borderRadius: '4px' }}>
            Note: This tool creates query for question against open source osquery schema and executes on a standalone osquery instance to show sample results of query.
          </Alert>
          {children}
      </Box>
      <ApiKeyModal open={openApiKeyModal} setOpen={setOpenApiKeyModel} />
      <SessionInactive />
    </Box>
  )
}