import * as React from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

type ComponentProps = {
    open: boolean,
    setOpen: (val: boolean) => void
}

export default function FormDialog({ open, setOpen }: ComponentProps) {
  const [apiKey, setApiKey] = React.useState<string>('');

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = () => {
    localStorage.setItem('openai-api-key', apiKey)
    setOpen(false);
  }

  const disableBtn = React.useMemo(() => {
    return !apiKey?.trim()
  }, [apiKey])

  return (
    <div>
      <Dialog open={open} disableEscapeKeyDown>
        <DialogTitle>OpenAI API Key</DialogTitle>
        <DialogContent>
          <DialogContentText>
          To workaround the rate limit you may be facing you can use your own ChatGPT API key, we will not store the API key and only use in this session.
          </DialogContentText>
          <TextField
            sx={{ mt: '16px' }}
            autoFocus
            margin="dense"
            id="api-key"
            label="API key"
            type="text"
            fullWidth
            variant="standard"
            value={apiKey}
            onChange={(e: any) => setApiKey(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} variant="outlined">Cancel</Button>
          <Button onClick={handleSave} variant="contained" disabled={disableBtn}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}