const generateRandomString = () => {
  let randomString = "";
  const charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 16; i++) {
    const randomIndex = Math.floor(Math.random() * charSet.length);
    const randomChar = charSet.charAt(randomIndex);
    randomString += randomChar;
  }
  return randomString
}

export const getGoogleOauthUrl = () => {
    const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`

    const options = {
      redirect_uri: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT as string,
      client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string,
      access_type: 'offline',
      response_type: 'code',
      prompt: 'consent',
      scope: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'openid',
      ].join(' '),
      state: generateRandomString()
    }

    const qs = new URLSearchParams(options)

    return `${rootUrl}?${qs.toString()}`
}
