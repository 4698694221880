import { createContext, useContext, useEffect, useState } from 'react'
import { authenticationAPI } from '../apis'
import { IRefreshTokenResponse } from '../apis/authentication.api'
import { getUserInfo, isValidJWT } from '../utils/jwt-util'
import { User } from '../utils/types'

interface IAuthContext {
  isAuthenticated: boolean
  isLoading: boolean
  user: User | null,
  error: string | null
  login: (code: string, state: string) => void
  logout: () => void
}

export const authContext = createContext<IAuthContext>({} as IAuthContext)

const useProvideAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const idToken = localStorage.getItem('idToken')
    const refreshToken = localStorage.getItem('refreshToken')
    if (idToken && isValidJWT(idToken)) {
      try {
        const userInfo = getUserInfo(idToken)
        setIsAuthenticated(true)
        setUser(userInfo)
      } catch (err) {
        setIsAuthenticated(false)
        setUser(null)
      }
    } else if (refreshToken) {
      authenticationAPI.refreshAccessToken({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }).then((res: IRefreshTokenResponse) => {
        const userInfo = getUserInfo(res.id_token)
        setIsAuthenticated(true)
        setUser(userInfo)
      }).catch(() => {
        setIsAuthenticated(false)
        setUser(null)
      })
    } else {
      setIsAuthenticated(false)
      setUser(null)
    }
  }, [])

  const login = async (code: string, state: string) => {
    try {
      setIsLoading(true)
      const res = await authenticationAPI.getAccessToken({
        code,
        state,
        redirect_uri: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT || window.location.origin,
        grant_type: 'authorization_code'
      })
      const userInfo = getUserInfo(res.id_token)
      setIsAuthenticated(true)
      setUser(userInfo)
      setError(null)
      setIsLoading(false)
    } catch (err: any) {
      setIsAuthenticated(false)
      setError(err.message)
      setIsLoading(false)
    }
  }

  const logout = () => {
    authenticationAPI.logout()
    setIsAuthenticated(false)
  }

  return {
    isAuthenticated,
    isLoading,
    user,
    error,
    login,
    logout,
  }
}

interface Props {
  children: React.ReactNode
}

export const ProvideAuth = ({ children }: Props) => {
  const auth = useProvideAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}
