import { useEffect, useState } from "react"
import { Stack, Typography, Box, Skeleton, Tabs, Tab, Alert, Chip, Card, CardHeader, CardContent } from '@mui/material'
import { IResultResponse } from "../apis/query.api"
import SQLQueryResult from "./SQLQueryResult"
import windowsLogo from '../assets/img/windows-logo.svg'
import linuxLogo from '../assets/img/linux-logo.svg'
import rhelLogo from '../assets/img/rhel-logo.svg'
import macLogo from '../assets/img/mac-logo.svg'
import { PlatformEnum } from "../utils/constants";
import { SchemaOutlined } from "@mui/icons-material"
import PlatformSchema from "./PlatformSchema"

const sortCallback = (a: IResultResponse, b: IResultResponse) => {
  if (a.platform < b.platform) return -1
  if (a.platform > b.platform) return 1
  return 0
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{py: '16px' }}>
            {children}
          </Box>
        )}
      </div>
    );
}

type ComponentProps = {
  data: IResultResponse[]
  loading?: boolean
  error?: string
}

const SQLQueryResults = ({ data, loading, error }: ComponentProps) => {
    const [tab, setTab] = useState(0)

    useEffect(() => {
      const sortedData = data.sort(sortCallback)
      for (let i = 0; i < sortedData.length; i++){
        if (typeof sortedData[i].result !== 'string') {
          setTab(i)
          break
        }
      }
    }, [data])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setTab(newValue)
    }

    const Output = ({ children }: any) => {
        return (
          <Card variant="outlined">
            <CardHeader
              color="primary"
              title={<Typography variant="body1">Output</Typography>}
            />
            <CardContent>
              {children}
            </CardContent>
          </Card>
        )
    }

    const Logo = (platform: PlatformEnum) => {
      if (platform === PlatformEnum.WINDOWS){
        return <img src={windowsLogo} alt="logo" style={{ height: '24px', width: '24px' }} />
      } else if (platform === PlatformEnum.LINUX) {
        return <img src={linuxLogo} alt="logo" style={{ height: '24px', width: '24px' }} />
      } else if (platform === PlatformEnum.RHEL) {
        return <img src={rhelLogo} alt="logo" style={{ height: '24px', width: '24px' }} />
      } else if (platform === PlatformEnum.MAC) {
        return <img src={macLogo} alt="logo" style={{ height: '24px', width: '24px' }} />
      } else {
        return null
      }
    }

    if (loading) {
      return (
        <Output>
          <Stack gap={1}>
            <Skeleton variant="rectangular" height={20} />
            <Skeleton variant="rectangular" height={20} />
            <Skeleton variant="rectangular" height={20} />
          </Stack>
        </Output>
      )
    }

    if (error) {
      return (
        <Output>
          <Alert severity="error">{error}</Alert>
        </Output>
      )
    }

    if (!data.length) {
      return (
        <Output>
          <Alert severity="warning">No Data</Alert>
        </Output>
      )
    }

    return (
      <Output>
        <Box sx={{ flexGrow: 1 }}>
            <Tabs
              value={tab}
              onChange={handleChange}
              aria-label="styled tabs example"
            >
              {
                data.sort(sortCallback).map((res: IResultResponse, idx) => {
                  if (typeof res.result === 'string') {
                    return <Tab
                      key={idx}
                      label={
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ position: 'relative' }}>
                          {Logo(res.platform as PlatformEnum)}
                          <p>{res.platform}</p>
                          <Chip sx={{ textTransform: 'lowercase' }} size="small" label={'0 results'} color="success" variant="outlined" />
                        </Stack>
                      }
                    />
                  } else {
                    return <Tab
                      key={idx}
                      label={
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ position: 'relative' }}>
                          {Logo(res.platform as PlatformEnum)}
                          <p>{res.platform}</p>
                          <Chip sx={{ textTransform: 'lowercase' }} size="small" label={`${res.result.length} results`} color="success" variant="outlined" />
                        </Stack>
                      }
                    />
                  }
                })
              }
            </Tabs>
            <div>
              {
                data.map((res: IResultResponse, idx) => {
                  return (
                    <TabPanel key={idx} value={tab} index={idx}>
                      <SQLQueryResult data={res} />
                    </TabPanel>
                  )
                })
              }
            </div>
        </Box>
      </Output>
    )
}

export default SQLQueryResults

