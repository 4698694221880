import { useEffect, useState } from 'react'
import { Skeleton, Divider, Container, Stack, Collapse, Box, TextField as MuiTextField, Typography, List, ListItemText as MuiListItemText, styled, InputAdornment, IconButton } from '@mui/material'
import { AddCircleOutlineOutlined, DatasetOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, RemoveCircleOutlineOutlined, SearchOutlined } from '@mui/icons-material'
import queryAPI from '../apis/query.api'
import logo from '../assets/img/uptycs-logo.png'
import './SchemaView.css'
import AppLogo from './common/AppLogo'

type CollapseState = {
  [key: number]: boolean
}

type TableColumn = {
  name: string
  data_type: string
  description?: string
}

type TableSchema = {
  table_name: string
  description?: string
  columns: TableColumn[]
  hidden?: boolean
}

type TableSchemaRes = {
  Tables: TableSchema[]
}

const ListItemText = styled(MuiListItemText)(() => ({
  '&.MuiListItemText-root::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
  },
  '&.MuiListItemText-root': {
    paddingTop: '4px',
    paddingBottom: '4px',
  }
}))

const TextField = styled(MuiTextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '16px',
    width: '600px',
  },
}))

const SearchInput = ({ onSearch }: any) => {
  const [searchTerm, setSearchTerm] = useState('')

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value)
    onSearch(e.target.value)
  }

  return (
    <TextField
      id="search-db-table-schema"
      size="small"
      type="search"
      fullWidth
      label={undefined}
      placeholder="Search table/column..."
      value={searchTerm}
      onChange={handleChange}
      InputProps={{
        startAdornment: <InputAdornment position="start">
          <SearchOutlined />
        </InputAdornment>
      }}
    />
  );
}

type ComponentProps = {
  platform: string
  tableSchema: TableSchema[]
  loading?: boolean
}

const PlatformSchema = ({ platform, loading, tableSchema }: ComponentProps) => {
  // const [tableSchema, setTableSchema] = useState<TableSchema[]>([])
  const [collapseState, setCollapseState] = useState<CollapseState>({})
  // const [error, setError] = useState<string | null>(null)
  // const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   setLoading(true)
  //   queryAPI.getSchema().then((res: TableSchemaRes) => {
  //     setTableSchema(res.Tables)
  //   }).catch((error: any) => {
  //     setError(error.message)
  //   }).finally(() => setLoading(false))
  // }, [])

  // const handleSearch = (searchTerm: string) => {
  //   const filteredResult = tableSchema.map((item: TableSchema) => {
  //     const matchedColumns = item.columns.filter((col: TableColumn) => col.name.includes(searchTerm))
  //     item.hidden = (item.table_name.includes(searchTerm) || matchedColumns.length > 0) ? false : true
  //     return item
  //   })
  //   setTableSchema(filteredResult)
  // }

  const handleCollapseState = (id: number, state: boolean) => {
    setCollapseState({ ...collapseState, [id]: state })
  }

  const LoadingSkeleton = <Stack gap={1} sx={{ py: '8px' }}>
    <Skeleton variant="rectangular" height={20} />
    <Skeleton variant="rectangular" height={20} />
    <Skeleton variant="rectangular" height={20} />
  </Stack>

  return (
    <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          { loading ? LoadingSkeleton :
            tableSchema.map((schema: TableSchema, idx) => {
              if (schema.hidden) return null
              return (
                <div key={`${schema.table_name}-${idx}`}>
                  <Stack sx={{ py: '8px' }} direction="row" alignItems="center" spacing={1}>
                    <DatasetOutlined fontSize="small" style={{ color: 'rgba(37,38,94,.67)' }} />
                    <div>
                      <Typography variant="body2" sx={{ display: 'inline-block', overflowWrap: 'anywhere' }}>{schema.description}</Typography>
                      &nbsp;
                      <Typography variant="body2" sx={{ display: 'inline-block', fontStyle: "italic", fontWeight: '500' }}>[{schema.table_name}]</Typography>
                    </div>
                    {collapseState[idx] !== true ? (
                      <div style={{ marginLeft: 'auto' }}>
                        <IconButton size="small" aria-label="settings">
                          <KeyboardArrowDownOutlined fontSize="small" onClick={() => handleCollapseState(idx, true)} sx={{ color: 'rgba(37,38,94,.67)', cursor: 'pointer' }} />
                        </IconButton>
                      </div>
                    ) : (
                      <div style={{ marginLeft: 'auto' }}>
                        <IconButton size="small" aria-label="settings">
                          <KeyboardArrowUpOutlined fontSize="small" onClick={() => handleCollapseState(idx, false)} sx={{ color: 'rgba(37,38,94,.67)', cursor: 'pointer' }} />
                        </IconButton>
                      </div>
                    )}
                  </Stack>
                  <Collapse
                    key={`collapsible-list-${idx}`}
                    in={collapseState[idx] === true}
                    timeout='auto'
                    unmountOnExit
                  >
                    <List sx={{ marginLeft: '12px', pt: 0 }} className="table-col-details">
                      {schema.columns.map((col, idx) => {
                        return (
                          <ListItemText key={`${col.name}-${idx}`} sx={{ m: 0, borderLeft: '1px solid #D9D9D9' }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Divider sx={{ width: '12px' }} />
                              <Stack direction="column" spacing={0} alignItems="stretch">
                                <Typography variant="body2">{col.description}</Typography>
                                <div>
                                  <Typography variant="body2" sx={{ display: 'inline-block' }}>{col.name}</Typography>
                                  <Typography variant="body2" sx={{ display: 'inline-block', color: 'primary.light', textTransform: 'lowercase' }}>[{col.data_type}]</Typography>
                                </div>
                              </Stack>
                            </Stack>
                          </ListItemText>
                        )
                      })}
                    </List>
                  </Collapse>
                </div>
              )
            })
          }
        </Box>
    </Box>
  )
}

export default PlatformSchema
