import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { ListItem, Typography } from '@mui/material';
import { ListItemButton } from '@mui/material';
import { ListItemIcon as MuiListItemIcon } from '@mui/material';
import { ListItemText as MuiListItemText} from '@mui/material/';
import { Link } from 'react-router-dom';
import SchemaIcon from '@mui/icons-material/Schema';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import { Stack, styled } from '@mui/material'
import { useAuth } from '../hooks';
import { AddOutlined, ChatBubbleOutlineOutlined, HistoryOutlined, HomeOutlined, LogoutOutlined, SchemaOutlined, VpnKeyOutlined } from '@mui/icons-material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import AppLogo from './common/AppLogo';
import { useLocation } from 'react-router-dom'
import logo from '../assets/img/uptycs-logo.png'
import { useAppContext } from '../hooks/app-context';
import { useEffect, useState } from 'react';
import ApiKeyModal from './ApiKeyModal'

const drawerWidth = 240;

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: '1.334',
  },
}));

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  '&.MuiListItemIcon-root': {
    minWidth: '32px',
  },
}));

type ComponentProps = {
  setPageTitle?: (title: string) => void
}

const AppSideBar: React.FC<any> = ({ setPageTitle }: ComponentProps) => {
  const { questions } = useAppContext()
  const location = useLocation()
  const [selectedMenu, setSelectedMenu] = useState<string>('home')
  const [openApiKeyModal, setOpenApiKeyModel] = useState<boolean>(false)
  const { logout } = useAuth() 

  useEffect(() => {
    if (location.pathname.includes('home'))
      setSelectedMenu('home')
    if (location.pathname.includes('search'))
      setSelectedMenu('search')
    else if (location.pathname.includes('history'))
      setSelectedMenu('history')
    else if (location.pathname.includes('schema'))
      setSelectedMenu('schema') 
  }, [location])

  const listItemStyle = {
    "& .Mui-selected": {
      background: 'rgb(237, 231, 246)',
      color: 'rgb(103, 58, 183)',
      borderRight: '2px solid',
      "& .MuiListItemIcon-root": {
        color: "primary.main"
      }
    },
    "&:hover": {
      background: 'rgb(237, 231, 246)',
      color: 'rgb(103, 58, 183)',
      "& .MuiListItemIcon-root": {
        color: "primary.main"
      }
    }
  }

  const handleMenuSelection = (menu: string, pageTitle: string) => {
    setSelectedMenu(menu)
    // setPageTitle?.(pageTitle)
  }

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
        display: 'flex',
        maxHeight: '100vh',
        overflow: 'hidden',
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar>
        <ListItem button component={Link} to="/home" sx={{ height: '100%', display: "flex", gap: '8px', alignItems: "center"}}>
          <AppLogo height="24px" compact />
          <Stack direction="row" spacing={0.5} alignItems="center" sx={{ alignSelf: 'flex-end' }}>
            <Typography variant="body2">by</Typography>
            <img src={logo} alt="logo" style={{ height: '1rem' }} />
          </Stack>
        </ListItem>
      </Toolbar>
      <Divider />
      <List sx={{ height: '100%', overflow: 'auto' }}>
        <ListItem button sx={listItemStyle} key="new-query" disablePadding component={Link} to="/search" onClick={() => handleMenuSelection('search', '')}>
          <ListItemButton selected={selectedMenu === 'search'}>
            <ListItemIcon>
              <AddOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="New question" />
          </ListItemButton>
        </ListItem>
        <ListItem button sx={listItemStyle} key="home" disablePadding component={Link} to="/home" onClick={() => handleMenuSelection('home', '')}>
          <ListItemButton selected={selectedMenu === 'home'}>
            <ListItemIcon>
              <HomeOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Home" />
          </ListItemButton>
        </ListItem>
        {questions.map((question, index) => (
          <ListItem button sx={listItemStyle} key={`${question}${index}`} disablePadding component={Link} to="/home" state={{question}} onClick={() => handleMenuSelection(`question-${question.replace(/\s/g,'')}`, 'Search')}>
            <ListItemButton selected={selectedMenu === `question-${question.replace(/\s/g,'')}`}>
              <ListItemIcon>
                <ChatBubbleOutlineOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={question}
                primaryTypographyProps={{ variant: 'body2', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <div style={{ marginTop: 'auto' }}>
      <Divider />
      <List onChange={(e: any) => console.log(e.target?.value)} sx={{ paddingBottom: '16px' }}>
        <ListItem button sx={listItemStyle} key="schema-tab" disablePadding component={Link} to="/schema" onClick={() => handleMenuSelection('schema', 'Schema')}>
          <ListItemButton selected={selectedMenu === 'schema'}>
            <ListItemIcon>
              <SchemaOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="OSquery schema" />
          </ListItemButton>
        </ListItem>
        <ListItem button sx={listItemStyle} key="history-tab" disablePadding onClick={() => setOpenApiKeyModel(true)}>
          <ListItemButton>
            <ListItemIcon>
              <VpnKeyOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="OpenAI API key" />
          </ListItemButton>
        </ListItem>
        <ListItem button sx={listItemStyle} key="history-tab" disablePadding component={Link} to="/history" onClick={() => handleMenuSelection('history', 'History')}>
          <ListItemButton selected={selectedMenu === 'history'}>
            <ListItemIcon>
              <HistoryOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="History" />
          </ListItemButton>
        </ListItem>
        <ListItem sx={listItemStyle} key="logout-btn" disablePadding onClick={() => logout()}>
          <ListItemButton>
            <ListItemIcon>
              <LogoutOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }} primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
      </div>
      <ApiKeyModal open={openApiKeyModal} setOpen={setOpenApiKeyModel} />
    </Drawer>
  );
}

export default AppSideBar
