import { useRef, useState } from 'react'
import { Popover, Typography, Stack, Tooltip, Box, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ContentCopyOutlined } from '@mui/icons-material'

interface Props {
  value?: string
}

const StyledPopover = styled(Popover)(() => ({
  pointerEvents: 'none',
  '& .MuiPopover-paper': {
    pointerEvents: 'auto',
    borderRadius: 8,
    maxWidth: '600px',
  },
}))

const CopyTextToClipboard = ({ value }: Props) => {
  const [openedPopover, setOpenedPopover] = useState(false)
  const popoverAnchor = useRef(null)

  const popoverEnter = () => {
    setOpenedPopover(true)
  }

  const popoverLeave = () => {
    setOpenedPopover(false)
  }

  const copyToClipboard = (val: string) => {
    navigator.clipboard.writeText(val)
    setOpenedPopover(false)
  }

  const id = openedPopover ? 'mouse-over-popover' : undefined

  if (!value) return null

  return (
    <>
      <div
        ref={popoverAnchor}
        style={{
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        aria-owns={openedPopover ? id : undefined}
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
      >
        {value}
      </div>
      <StyledPopover
        id={id}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
        disableRestoreFocus
      >
        <Box sx={{ p: '16px' }}>
          <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
            <Typography variant="body2" color="basic.contrastText">
              {value}
            </Typography>
            <Tooltip title="Copy" placement="top">
                <IconButton
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation()
                        copyToClipboard(value)
                    }}
                >
                <ContentCopyOutlined fontSize="small" />
                </IconButton>
            </Tooltip>
          </Stack>
        </Box>
      </StyledPopover>
    </>
  )
}

export default CopyTextToClipboard
