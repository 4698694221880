import { useCallback, useEffect, useMemo, useState } from 'react'
import { IconButton, Avatar, Skeleton, Grid, Card, CardContent, CardHeader, Divider, Container, Stack, Collapse, Box, TextField as MuiTextField, Typography, List, ListItemText as MuiListItemText, styled, InputAdornment } from '@mui/material'
import { AddCircleOutlineOutlined, DatasetOutlined, KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, RemoveCircleOutlineOutlined, SearchOutlined, MoreVertOutlined } from '@mui/icons-material'
import queryAPI from '../apis/query.api'
import logo from '../assets/img/uptycs-logo.png'
import './SchemaView.css'
import AppLogo from './common/AppLogo'
import PlatformSchema from './PlatformSchema'
import { PlatformEnum } from '../utils/constants'
import windowsLogo from '../assets/img/windows-logo.svg'
import linuxLogo from '../assets/img/linux-logo.svg'
import rhelLogo from '../assets/img/rhel-logo.svg'
import macLogo from '../assets/img/mac-logo.svg'

type CollapseState = {
  [key: number]: boolean
}

type TableColumn = {
  name: string
  data_type: string
  description?: string
}

type TableSchema = {
  table_name: string
  description?: string
  columns: TableColumn[]
  hidden?: boolean
}

type TableSchemaRes = {
  Tables: Record<string, TableSchema[]>[]
}

const ListItemText = styled(MuiListItemText)(() => ({
  '&.MuiListItemText-root::before': {
    content: '""',
    display: 'block',
    position: 'absolute'
  }
}))

const TextField = styled(MuiTextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    marginRight: '8px',
    minWidth: '320px',
  },
}))

const Logo = (platform: PlatformEnum) => {
  if (platform === PlatformEnum.WINDOWS){
    return <img src={windowsLogo} alt="logo" style={{ height: '24px', width: '24px' }} />
  } else if (platform === PlatformEnum.LINUX) {
    return <img src={linuxLogo} alt="logo" style={{ height: '24px', width: '24px' }} />
  } else if (platform === PlatformEnum.RHEL) {
    return <img src={rhelLogo} alt="logo" style={{ height: '24px', width: '24px' }} />
  } else if (platform === PlatformEnum.MAC) {
    return <img src={macLogo} alt="logo" style={{ height: '24px', width: '24px' }} />
  } else {
    return null
  }
}

const Output = ({ searchTerm, handleOnChange, children }: any) => {
  return (
    <Card variant="outlined">
      <CardHeader
        color="primary"
        title={<Typography variant="body1">OSquery Schema</Typography>}
        action={
          <TextField
            id="search-db-table-schema"
            size="small"
            type="search"
            label={undefined}
            placeholder="Search table/column..."
            value={searchTerm}
            onChange={handleOnChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            }}
          />
        }
      />
      <CardContent>
        {children}
      </CardContent>
    </Card>
  )
}

const SchemaView = () => {
  const [tableSchema, setTableSchema] = useState<Record<string, TableSchema[]>[]>([])
  const [collapseState, setCollapseState] = useState<CollapseState>({})
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    setLoading(true)
    queryAPI.getSchema().then((res: TableSchemaRes) => {
      setTableSchema(res.Tables)
    }).catch((error: any) => {
      setError(error.message)
    }).finally(() => setLoading(false))
  }, [])

  const handleSearch = (searchTerm: string) => {
    const filteredResult = tableSchema.map((obj: Record<string, TableSchema[]>) => {
      const platform = Object.keys(obj)[0]
      obj[platform] = obj[platform].map((item: TableSchema) => {
        const matchedColumns = item.columns.filter((col: TableColumn) => col.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) || col.description?.toLocaleLowerCase()?.includes(searchTerm.toLocaleLowerCase()))
        item.hidden = (item.table_name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) || item.description?.toLocaleLowerCase()?.includes(searchTerm.toLocaleLowerCase()) || matchedColumns.length > 0) ? false : true  
        return item
      })
      return obj
    })
    setTableSchema(filteredResult)
  }

  const handleCollapseState = (id: number, state: boolean) => {
    setCollapseState({ ...collapseState, [id]: state })
  }

  const handleOnChange = (e: any) => {
    setSearchTerm(e.target.value)
    handleSearch(e.target.value)
  }

  const MemoisedSchemaView = useMemo(() => {
    return (
      <Grid container spacing={2}>
      {
        tableSchema.map((obj: Record<string, TableSchema[]>, idx) => {
          const platform = Object.keys(obj)[0]
          return (
            <Grid item sm={12} md={6}>
              <Card variant="outlined">
                <CardHeader
                  color="primary"
                  avatar={Logo(platform.toLocaleLowerCase() as PlatformEnum)}
                  action={
                    <IconButton aria-label="settings">
                      {collapseState[idx] !== true ? (
                        <KeyboardArrowDownOutlined onClick={() => handleCollapseState(idx, true)} sx={{ color: 'rgba(37,38,94,.67)', cursor: 'pointer' }} />
                      ) : (
                        <KeyboardArrowUpOutlined onClick={() => handleCollapseState(idx, false)} sx={{ color: 'rgba(37,38,94,.67)', cursor: 'pointer' }} />
                      )}
                    </IconButton>
                  }
                  title={platform}
                />
                <Collapse
                  key={`collapsible-list-${idx}`}
                  in={collapseState[idx] === true}
                  timeout='auto'
                  unmountOnExit
                >
                  <CardContent>
                    <PlatformSchema platform={platform} tableSchema={obj[platform]} />
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          )
        })
      }
      </Grid>
    )
  }, [tableSchema, collapseState])

  if (loading) return (
    <Output searchTerm={searchTerm} handleOnChange={handleOnChange}>
      <Stack gap={1} sx={{ py: '8px' }}>
        <Skeleton variant="rectangular" height={20} />
        <Skeleton variant="rectangular" height={20} />
        <Skeleton variant="rectangular" height={20} />
      </Stack>
    </Output>
  )

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Output searchTerm={searchTerm} handleOnChange={handleOnChange}>
        {MemoisedSchemaView}
      </Output>
    </Box>
  )
}

export default SchemaView
