import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  timeout: 20000, // 20 sec
  // withCredentials: true,
})

export { apiClient }
