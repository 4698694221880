import { AxiosResponse } from 'axios'
import { apiClient } from '../utils/request'

export interface IRunQueryReqBody {
  query: string
}
export interface IRunQueryResponse {
  id: string
  query: string
  msg?: string
}

export interface IGenerateQueryReqBody {
  question: string
  api_key?: string
}

export interface IGenerateQueryResponse {
  id: string
  query: string
  question: string
  msg?: string
}

export interface IGetResultReqBody {
  id: string
}

export interface IResultResponse {
  hostname: string,
  platform: string,
  result: any[] | string
}

export interface IGetResultApiResponse {
  results: IResultResponse[] | string
  msg?: string
}

class QueryAPI {
  async getSchema(): Promise<any> {
    const jwt = localStorage.getItem('idToken')
    const res: AxiosResponse = await apiClient({
      method: 'GET',
      url: `/getSchemav2`,
      headers: {
        "Authorization": `Bearer ${jwt}`,
      },
    })
    return res.data
  }

  async runQuery(data: IRunQueryReqBody): Promise<IRunQueryResponse> {
    const jwt = localStorage.getItem('idToken')
    const res: AxiosResponse = await apiClient({
      method: 'POST',
      url: `/runQuery`,
      data,
      headers: {
        "Authorization": `Bearer ${jwt}`,
      },
    })
    return res.data
  }

  async generateQuery(data: IGenerateQueryReqBody): Promise<IGenerateQueryResponse> {
    const jwt = localStorage.getItem('idToken')
    const res: AxiosResponse = await apiClient({
      method: 'POST',
      url: `/generateQuery`,
      data,
      headers: {
        "Authorization": `Bearer ${jwt}`,
      },
    })
    return res.data
  }

  async getResult(data: IGetResultReqBody): Promise<IGetResultApiResponse> {
    const jwt = localStorage.getItem('idToken')
    const res: AxiosResponse = await apiClient({
      method: 'POST',
      url: `/getResult`,
      data,
      headers: {
        "Authorization": `Bearer ${jwt}`,
      },
    })
    return res.data
  }
}

const queryAPI = new QueryAPI()

export default queryAPI
