import jwtDecode, { JwtPayload } from 'jwt-decode'
import { User } from './types'

interface CustomJwtPayload extends JwtPayload {
    email: string
    name: string
    picture?: string
}

const getUserInfo = (token: string): User | null => {
  const jwtPayload = jwtDecode<CustomJwtPayload>(token)
  if (jwtPayload) {
    return {
        email: jwtPayload.email,
        name: jwtPayload.name,
        picture: jwtPayload.picture,
    }
  }
  return null
}

const isValidJWT = (token: string) => {
  const jwtPayload = jwtDecode<CustomJwtPayload>(token)
  if (jwtPayload) {
    return jwtPayload.iss === 'https://accounts.google.com' && jwtPayload.aud === process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID
  }
  return false
}

export {
  getUserInfo,
  isValidJWT,
}
