import React from 'react'

interface Props {
  children: React.ReactNode
}

class ErrorBoundary extends React.Component<Props> {
  state = { hasError: false }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <div>
                Please try{' '}
                <div
                  role="presentation"
                  style={{
                    cursor: 'pointer',
                    display: 'inline-block',
                    color: '#1890FF',
                    fontSize: '12px',
                    textDecoration: 'underline',
                  }}
                  onClick={() => window.location.reload()}
                >
                  reloading this page
                </div>
              </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
