import { Stack } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import osqueryLogo from '../../assets/img/osquery-logo.svg'
import chatGPTLogo from '../../assets/img/chatgpt-logo.svg'

type ComponentProps = {
    height?: string,
    compact?: boolean,
}

const AppLogo = ({ height, compact }: ComponentProps) => {
    return (
        <Stack direction="row" spacing={compact ? 0.5 : 1} alignItems="center">
            <img src={osqueryLogo} alt="logo" style={{ height: height || '48px' }} />
            <AddIcon fontSize={compact ? "small" : "medium" } />
            <img src={chatGPTLogo} alt="logo" style={{ height: height || '48px' }} />
        </Stack>
    )
}

export default AppLogo
