import * as React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@mui/material';
import { useAuth } from '../hooks';
import { useIdleTimer } from 'react-idle-timer';

export default function SessionInactive() {
  const [open, setOpen] = React.useState<boolean>(false);
  const { logout } = useAuth()

  const onIdle = () => {
    setOpen(true);
  };

  const { isIdle } = useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 min
    debounce: 250,
    onIdle: onIdle,
  })

  return (
    <div>
      <Dialog open={open} disableEscapeKeyDown>
        <DialogContent>
          <DialogContentText>
            System has detected no activity in the past 30 minutes. Please login again to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => logout()} variant="contained">Login</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}