import { useEffect, useState } from 'react'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { Box, Grid, Typography, Button, Stack, CircularProgress } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import queryString from 'query-string'
import { useAuth } from '../hooks'
import { getGoogleOauthUrl } from '../utils/google-oauth'
import { ReactComponent as GoogleLogo } from '../assets/img/google-logo.svg'
import logo from '../assets/img/uptycs-logo.png'
import osqueryLogo from '../assets/img/osquery-logo.svg'
import chatGPTLogo from '../assets/img/chatgpt-logo.svg'
import AppLogo from './common/AppLogo'

const LoginPage = () => {
  const [redirectToReferrer, setRedirectToReferrer] = useState<boolean>(false)
  const [referrerPath, setReferrerPath] = useState({ pathname: '/search' })
  const location = useLocation()
  const navigate = useNavigate()
  const { isAuthenticated, login, isLoading } = useAuth()

  useEffect(() => {
    if (location.state) {
      setReferrerPath(location.state.from)
    }
  }, [location.state])
  
  useEffect(() => {
    // google oauth callback params
    const queryParams: any = location.search
      ? queryString.parse(location.search)
      : queryString.parse(location.hash)

    if (queryParams.code && queryParams.state) {
      login(queryParams.code, queryParams.state)
    }
  }, [])

  useEffect(() => {
    if (isLoading) return
    if (isAuthenticated) setRedirectToReferrer(true)
  }, [isLoading, isAuthenticated, navigate])

  if (redirectToReferrer) {
    return <Navigate to={referrerPath} />
  }

  return (
    <Grid container spacing={0} sx={{ width: '100vw', height: '100vh' }}>
      <Grid item xs={12} sm={6} sx={{ color: '#ffffff', backgroundColor: '#621BD0', display: 'flex', alignItems: 'center' }}>
        <Box component="div" sx={{ p: '64px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Typography variant="h5">Welcome to OSquery chatGPT</Typography>
          <Typography sx={{ lineHeight: '1.8rem' }}>
            Osquery does a pretty good job of abstracting system commands acorss multiple operating system behind a sql 
            interface however osquery schema covers a wide variety of telemetry that can be collected 
            from system, learning what queries to on which table to extract the information required can 
            still be pretty intensive task.
          </Typography>
          <Typography sx={{ lineHeight: '1.8rem' }}>
            <a style={{ color: '#fff' }} href="/" target="_blank" rel="noreferrer">
              <b> ask.uptycs.ai </b>
            </a>allows you to query your system through osquery using Natural Language, 
            thereby easing out the task of querying your system beyond sql using natural language. 
            It uses Open AI SQL translate API on publicly available
            <a style={{ color: '#fff' }} href=" https://osquery.io/schema/" target="_blank" rel="noreferrer">
              <b> osquery schema </b>
            </a>(send schema context in API) 
            to get the sql and executes it against OSS osquery to validate the sql and also produce sample results.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} sx={{ backgroundColor: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box
          sx={{
            py: { xs: '1rem', sm: '2rem', md: '3rem' },
            px: { xs: '2rem', sm: '4rem', md: '6rem' },
            flex: 0,
            borderRadius: '8px',
            border: '1px solid #D9D9D9',
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            alignItems: 'center',
          }}
        >
          <AppLogo />

          <Stack direction="column" spacing={0}>
            <Typography sx={{ color: '#969696', fontSize: '20px', whiteSpace: 'nowrap' }} variant="subtitle1">Making osquery Simpler using chatGPT</Typography>
            <Stack direction="row" spacing={0.5} alignItems="center" sx={{ marginLeft: 'auto' }}>
              <Typography sx={{ color: '#969696' }}>by</Typography>
              <img src={logo} alt="logo" style={{ height: '1.2rem' }} />
            </Stack>
          </Stack>
          {
            isLoading ? (
              <Stack direction="row" spacing={1} sx={{ py: '8px' }} alignItems="center">
                <CircularProgress />
                <Typography sx={{ whiteSpace: 'nowrap'}} >Login using Google...</Typography>
              </Stack>
            ) : (
              <Button
                color="inherit"
                href={getGoogleOauthUrl()}
                variant="outlined"
                startIcon={<GoogleLogo style={{ height: '2rem' }} />}
                sx={{ py: '8px', px: '16px', border: '1px solid #D9D9D9', borderRadius: '8px' }}
              >
                Login with Google
              </Button>
            )
          }
        </Box>
      </Grid>
    </Grid>
  );
};

export default LoginPage