export interface User {
    email: string
    name: string
    picture?: string
}

export enum PlatformEnum {
    LINUX='linux',
    WINDOWS='windows',
    RHEL='rhel',
    MAC='mac',
}