import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks'

const RequireAuth = () => {
  const { isAuthenticated } = useAuth()
  const location = useLocation()

  if (!isAuthenticated) {
    return <Navigate to={{ pathname: '/login' }} state={{ from: location }} />
  }

  return <Outlet />
}

export default RequireAuth
